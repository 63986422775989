import { Box, Flex, HStack, IconButton, Image, Link } from "@chakra-ui/react";
import logo from "../../../assets/logo/logo_white.png";
import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";

const DemoFooter = () => {
  const footerLinks = [
    { label: "Vissza a főoldalra", link: "https://wobble.hu/" },
    { label: "Blog", link: "https://wobble.hu/blog" },
    { label: "Kipróbálom", link: "https://app.wobble.hu/" },
    { label: "Adatvédelmi tájékoztató", link: "https://app.wobble.hu/adatvedelem" },
    { label: "ÁSZF", link: "https://app.wobble.hu/aszf" },
  ];

  const socialLinks = [
    { icon: FaFacebook, link: "https://www.facebook.com/people/Wobble-App/61566480490390/" },
    { icon: FaYoutube, link: "https://www.youtube.com/@WobbleAppHun/videos" },
    { icon: FaInstagram, link: "https://www.instagram.com/wobble_app/" },
  ];

  return (
    <Box bg="gray.800" color="white" py={4} px={4}>
      <Flex
        direction={{ base: "column", md: "row" }}
        align="center"
        justify="space-between"
        maxW="container.xl"
        mx="auto"
      >
        <Box mb={{ base: 4, md: 0 }}>
          <Image
            src={logo}
            alt="Logo"
            w="100px"
            objectFit="contain"
          />
        </Box>

        {/* Links */}
        <HStack
          spacing={4}
          mb={{ base: 4, md: 0 }}
          flexWrap="wrap"
          justify={{ base: "center", md: "start" }}
        >
          {footerLinks.map((link, idx) => (
            <Link
              key={idx}
              href={link.link}
              color="gray.300"
              _hover={{ color: "white", textDecoration: "underline" }}
              fontSize="sm"
              isExternal
            >
              {link.label}
            </Link>
          ))}
        </HStack>

        <HStack spacing={4}>
          {socialLinks.map((social, idx) => (
            <IconButton
              key={idx}
              aria-label={`Link to ${social.link}`}
              icon={<social.icon />}
              as="a"
              href={social.link}
              target="_blank"
              rel="noopener noreferrer"
              variant="ghost"
              color="gray.300"
              _hover={{ color: "white" }}
            />
          ))}
        </HStack>
      </Flex>
    </Box>
  );
};

export default DemoFooter
