import {
  Flex,
  HStack,
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Link as ChakraLink,
  DrawerBody,
  Button,
  Image,
  Spacer,
  useDisclosure,
  useBreakpointValue,
  DrawerHeader,
  Box,
  VStack,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { darkColor, mainColor } from "../LandingPage";
import logo from "../../../assets/logo/logo_white.png";
import { FaBars } from "react-icons/fa";

type NavButton = {
  label: string;
  link: string;
};

const DemoNavbar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const navButtons: NavButton[] = [
    { label: "Vissza a főoldalra", link: "https://wobble.hu/" },
    { label: "Blog", link: "https://wobble.hu/blog" },
    { label: "Kipróbálom", link: "https://app.wobble.hu/" },
  ];

  const handleNavClick = (link: string) => {
    window.open(link, "_blank");
  };

  return (
    <Flex
      as="header"
      bg={mainColor}
      color="white"
      py={4}
      px={8}
      justify="space-between"
      align="center"
      position="sticky"
      top="0"
      zIndex="1000"
      w="100%"
    >
      <Image src={logo} alt="Logo" w="140px" objectFit="contain" />
      <Spacer />
      {/* Desktop Navigation */}
      {!isMobile && (
        <HStack spacing={4}>
          {navButtons.map((btn, idx) => (
            <ChakraLink
              key={idx}
              p={2}
              _hover={{ textDecoration: "none", bg: darkColor, rounded: 5 }}
              onClick={() => handleNavClick(btn.link)}
            >
              {btn.label}
            </ChakraLink>
          ))}
        </HStack>
      )}

      {isMobile && (
        <>
          <IconButton
            aria-label="Open Menu"
            icon={<FaBars />}
            display={{ base: "flex", lg: "none" }}
            onClick={onOpen}
            colorScheme="white"
          />
          <Drawer placement="top" onClose={onClose} isOpen={isOpen}>
            <DrawerOverlay />
            <DrawerContent bg={mainColor}>
              <DrawerCloseButton />
              <DrawerHeader>
                <Box h={34} w={140} objectFit="cover">
                  <Image src={logo} alt="logo" />
                </Box>
              </DrawerHeader>
              <DrawerBody mt={10}>
                <VStack spacing={4} align="start" mb={10}>
                  {navButtons.map((btn, idx) => (
                    <ChakraLink
                      key={idx}
                      color="white"
                      fontWeight="bold"
                      onClick={() => {
                        handleNavClick(btn.link);
                        onClose();
                      }}
                    >
                      {btn.label}
                    </ChakraLink>
                  ))}
                </VStack>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </>
      )}
    </Flex>
  );
};

export default DemoNavbar;
