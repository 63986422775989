import {
  Box,
  Flex,
  Button,
  VStack,
  HStack,
  IconButton,
  Link as ChakraLink,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Image,
} from "@chakra-ui/react";
import { FaBars } from "react-icons/fa";
import { Link } from "react-router-dom";
import { darkColor, lightColor, mainColor } from "./LandingPage";
import logo_white from "../../assets/logo/logo_white.png";
import logo from "../../assets/logo/logo_wobble.png";

const Navbar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Flex
        as="header"
        bg={mainColor}
        color="white"
        py={4}
        px={8}
        justify="space-between"
        align="center"
        position="sticky"
        top="0"
        zIndex="1000"
        w="100%"
      >
        <Box h={34} w={140} objectFit="cover">
          <Image src={logo_white} alt="logo" />
        </Box>
        <HStack
          spacing={{ base: 1, md: 2 }}
          display={{ base: "none", lg: "flex" }}
        >
          <ChakraLink
            href="#integration"
            p={1}
            ml={4}
            _hover={{ textDecoration: "none", bg: darkColor, rounded: 5 }}
          >
            Hogyan működik
          </ChakraLink>
          <ChakraLink
            href="#features"
            p={1}
            _hover={{ textDecoration: "none", bg: darkColor, rounded: 5 }}
          >
            Funkciók
          </ChakraLink>
          <ChakraLink
            href="#usage"
            p={1}
            _hover={{ textDecoration: "none", bg: darkColor, rounded: 5 }}
          >
            Kiknek?
          </ChakraLink>
          <ChakraLink
            href="#pricing"
            p={1}
            _hover={{ textDecoration: "none", bg: darkColor, rounded: 5 }}
          >
            Árak
          </ChakraLink>
          <ChakraLink
            href="#aboutus"
            p={1}
            _hover={{ textDecoration: "none", bg: darkColor, rounded: 5 }}
          >
            Kik vagyunk mi?
          </ChakraLink>
          <ChakraLink
            href="#contact"
            p={1}
            _hover={{ textDecoration: "none", bg: darkColor, rounded: 5 }}
          >
            Kapcsolat
          </ChakraLink>
          <ChakraLink
            href="#gyik"
            p={1}
            _hover={{ textDecoration: "none", bg: darkColor, rounded: 5 }}
          >
            GYIK
          </ChakraLink>
          <ChakraLink
            as={Link}
            p={1}
            rounded={5}
            to="/demo"
            bg={darkColor}
            _hover={{ textDecoration: "none", bg: lightColor }}
            target="_blank"
          >
            Demo foglalási oldal
          </ChakraLink>
          <ChakraLink
            as={Link}
            to="/blog"
            p={1}
            mr={2}
            _hover={{ textDecoration: "none", bg: darkColor }}
            target="_blank"
          >
            Blog
          </ChakraLink>
        </HStack>
        <HStack spacing={4} display={{ base: "none", lg: "flex" }}>
          <Button
            colorScheme="white"
            variant="link"
            as="a"
            href="https://app.wobble.hu/login"
            target="_blank"
            rel="noopener noreferrer"
          >
            Belépés
          </Button>
          <Button
            colorScheme="white"
            variant="outline"
            as="a"
            href="https://app.wobble.hu/signup_page"
            target="_blank"
            rel="noopener noreferrer"
            _hover={{
              backgroundColor: darkColor,
              textDecoration: "none",
            }}
          >
            Regisztráció
          </Button>
        </HStack>
        <IconButton
          aria-label="Open Menu"
          icon={<FaBars />}
          display={{ base: "flex", lg: "none" }}
          onClick={onOpen}
          colorScheme="white"
        />
      </Flex>
      {/* Mobile Drawer */}
      <Drawer placement="top" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton color="teal"/>
            <DrawerHeader>
              <Box h={34} w={140} objectFit="cover">
                <Image src={logo} alt="logo" />
              </Box>
            </DrawerHeader>
            <DrawerBody>
              <VStack spacing={4} align="start">
                <ChakraLink
                  color={mainColor}
                  fontWeight="bold"
                  href="#integration"
                  onClick={onClose}
                >
                  Hogyan működik
                </ChakraLink>
                <ChakraLink
                  color={mainColor}
                  fontWeight="bold"
                  href="#features"
                  onClick={onClose}
                >
                  Funkciók
                </ChakraLink>
                <ChakraLink
                  color={mainColor}
                  fontWeight="bold"
                  href="#usage"
                  onClick={onClose}
                >
                  Kiknek?
                </ChakraLink>
                <ChakraLink
                  color={mainColor}
                  fontWeight="bold"
                  href="#pricing"
                  onClick={onClose}
                >
                  Árak
                </ChakraLink>
                <ChakraLink
                  color={mainColor}
                  fontWeight="bold"
                  href="#aboutus"
                  onClick={onClose}
                >
                  Kik vagyunk mi?
                </ChakraLink>
                <ChakraLink
                  color={mainColor}
                  fontWeight="bold"
                  href="#contact"
                  onClick={onClose}
                >
                  Kapcsolat
                </ChakraLink>
                <ChakraLink
                  color={mainColor}
                  fontWeight="bold"
                  href="#gyik"
                  onClick={onClose}
                >
                  GYIK
                </ChakraLink>
                <ChakraLink
                  as={Link}
                  to="/demo"
                  fontWeight="bold"
                  color={mainColor}
                  onClick={onClose}
                  target="_blank"
                >
                  Demo foglalási oldal
                </ChakraLink>
                <ChakraLink
                  as={Link}
                  to="/blog"
                  fontWeight="bold"
                  color={mainColor}
                  onClick={onClose}
                  target="_blank"
                >
                  Blog
                </ChakraLink>
              </VStack>
            </DrawerBody>
            <DrawerFooter justifyContent="center">
              <Button
                as="a"
                href="https://app.wobble.hu/login"
                target="_blank"
                rel="noopener noreferrer"
                colorScheme="teal"
                width="200px"
                variant="outline"
                onClick={onClose}
                m={8}
              >
                Belépés
              </Button>
              <Button
                as="a"
                href="https://app.wobble.hu/signup_page"
                target="_blank"
                rel="noopener noreferrer"
                width="200px"
                colorScheme="teal"
                variant="outline"
                onClick={onClose}
                m={8}
              >
                Regisztráció
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  );
};

export default Navbar;
