import { mainColor, darkColor } from "./LandingPage";
import {
  Box,
  Heading,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text,
} from "@chakra-ui/react";

const FAQSection = () => {
  const color = mainColor;
  const borderColor = mainColor;
  const questionColor = darkColor;
  const hoverColor = "white";

  const faqs = [
    {
      question: "Mi a Wobble?",
      answer:
        "A Wobble egy olyan időpontfoglaló szoftver, amely egy rendszerben biztosítja a naptárkezelést, ügyfélkezelést, blogírást, e-mail küldést, egyedi kérdőívek készítését, valamint egyedi foglalási oldal és időpontfoglaló rendszert biztosít. Így segít egyszerűsíteni a vállalkozásod mindennapi működését és online jelenléted kezelését.",
    },
    {
      question: "Hogyan segít a Wobble a vállalkozásomon?",
      answer:
        "A Wobble egyszerűsíti az időpontfoglalási folyamatot, időt takarít meg mind a vállalkozónak, mind az ügyfeleinek, és segít javítani az online jelenlétét.",
    },
    {
      question: "A Wobble minden iparág számára megfelelő?",
      answer:
        "Minden olyan iparágban dolgozó vállalkozás számára alkalmas a Wobble, aki egy/egy szolgáltatást nyújt online vagy szolgáltató helyen, amihez időpont előzetes foglalása szükséges. Valamint azon vállalkozók számára, akik szeretnének egyetlen platformon digitális megoldásokkal egyszerűsíteni mindennapjaikat, ilyan pl. az email küldés, blog írás, vagy online megjelenés.",
    },
    {
      question: "Személyre szabhatom az időpontfoglaló oldalamat?",
      answer:
        "Természetesen! A Wobble lehetővé teszi, hogy személyre szabjad az időpontfoglaló oldalad, hogy illeszkedjen a márkád arculatához.",
    },
    {
      question: "Milyen támogatás érhető el, ha segítségre van szükségem?",
      answer:
        "Support csapatunk mindig rendelkezésre áll a kijelölt időpontokban ingyenes konzultációra, amit a https://book.wobble.hu oldalon lehet foglalni, vagy e-mailben az info@wobble.hu-ra. Ezen kívül blog cikkekben, videókban, Facebook és Instagram posztokban a lehető legjobban igyekszünk bemutatni a rendszert a könnyebb használat érdekében.",
    },
    {
      question: "Mennyibe kerül a teljes rendszer?",
      answer:
        "A Wobble időpontfoglaló rendszer, online foglalási oldal valamint a CRM rendszer 2999 Ft-t/ hó, vagy kedvezményesen 29 999 Ft/év (vagyis két hónap ingyen) áron elérhető.",
    },
  ];

  return (
    <Box id="gyik" color={color} py={10} px={4} maxW="6xl" mx="auto">
      <Heading as="h2" size="xl" textAlign="center" mb={8}>
        Gyakran ismételt kérdések (GYIK)
      </Heading>
      <Accordion allowToggle>
        {faqs.map((faq, index) => (
          <AccordionItem key={index} borderColor={borderColor}>
            <AccordionButton
              _hover={{
                bg: mainColor,
                color: hoverColor,
              }}
              _expanded={{
                bg: mainColor,
                color: mainColor,
              }}
            >
              <Box
                flex="1"
                textAlign="left"
                fontWeight="bold"
                color={questionColor}
                _hover={{
                  color: hoverColor,
                }}
              >
                {faq.question}
              </Box>
              <AccordionIcon
                color={questionColor}
                _hover={{
                  color: hoverColor,
                }}
              />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Text>{faq.answer}</Text>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Box>
  );
};

export default FAQSection;
