// App.tsx
import { Fragment, useState } from "react";
import {
  Container,
  Box,
  Tabs,
  TabList,
  Tab,
  Skeleton,
  Heading,
  Highlight,
  useHighlight,
  Mark,
} from "@chakra-ui/react";
import DemoNavbar from "./DemoNavbar";
import { mainColor } from "../LandingPage";
import DemoFooter from "./DemoFooter";

interface TabData {
  label: string;
  url: string;
}

const DemoPage = () => {
  // Define your tabs with labels and corresponding iframe URLs.
  const tabData: TabData[] = [
    {
      label: "Szépségszalon",
      url: "https://app.wobble.hu/demo-portal/lumiere-szalon",
    },
    {
      label: "Kertészet",
      url: "https://app.wobble.hu/demo-portal/botanical-heaven",
    },
    {
      label: "Coach",
      url: "https://app.wobble.hu/demo-portal/coach",
    },
    {
      label: "Autószerelő",
      url: "https://app.wobble.hu/demo-portal/automester",
    },
  ];

  const [iframeUrl, setIframeUrl] = useState<string>(tabData[0].url);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const handleIframeLoad = () => {
    setIsLoading(false);
  };

  const handleTabChange = (index: number) => {
    setIsLoading(true);
    setIframeUrl(tabData[index].url);
  };

  const chunks = useHighlight({
    text: "4 demo, 4 elképzelés. Képzeld el a saját foglalási oldalad!",
    query: ["4 Demo, 4 elképzelés", "saját foglalási oldalad"],
  });

  return (
    <>
      <DemoNavbar />
      <Container maxW="container.xl" py={8}>

        <Heading size="xl" maxW="80ch" mb={30}>
          {chunks.map((chunk, index) => {
            return chunk.match ? (
              <Mark
                key={index}
                css={{
                  fontStyle: "italic",
                  position: "relative",
                }}
              >
                {chunk.text}
                <img
                  style={{ position: "absolute", left: 0 }}
                  src="https://uploads-ssl.webflow.com/5fac11c3554384e2baf6481c/61c4dc7572d22f05ba26fd34_hero-underline.svg"
                  loading="lazy"
                  alt=""
                />
              </Mark>
            ) : (
              <Fragment key={index}>{chunk.text}</Fragment>
            );
          })}
        </Heading>

        <Tabs variant="line" isFitted onChange={handleTabChange}>
          <TabList mb="1em">
            {tabData.map((tab, index) => (
              <Tab key={index} fontWeight={500} color={mainColor}>
                {tab.label}
              </Tab>
            ))}
          </TabList>
        </Tabs>

        <Box position="relative" w="full" h={{ base: "500px", md: "750px" }}>
          <Skeleton
            isLoaded={!isLoading}
            height="100%"
            borderRadius="lg"
            startColor="gray.100"
            endColor="gray.400"
          >
            <iframe
              src={iframeUrl}
              width="100%"
              height="100%"
              title="Content Frame"
              style={{ display: "block" }}
              onLoad={handleIframeLoad}
            ></iframe>
          </Skeleton>
        </Box>
      </Container>
      <DemoFooter />
    </>
  );
};

export default DemoPage;
